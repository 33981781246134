"use client"

import type React from "react"
import { createContext, useContext, useEffect, useState, useMemo } from "react"
import {  useRouter } from "next/navigation"
import { apiService } from "@/utlity/api-service"
import { API_LIST } from "@/utlity/api-list"
import { getToken } from "firebase/messaging"
import { messaging } from "@/lib/firebase.config"
import { DeviceTypes } from "@/types/notifications"
import { Preference } from "@/types/settings"
import { useLayout } from "./layout-side-provider"
import { useTheme } from "next-themes"
import { useExamStore } from "@/store/use-exam-store";

interface User {
  userName: string;
  email: string;
  firstName?: string;
  lastName?: string;
  roleId: number;
}

export interface IRolePermissions {
  subjectId: number;
  subject: string;
  action: string;
}

interface SignInResponse {
  message: string;
  accessToken: string;
  authToken: string;
  user: {
    id: number;
    email: string;
    idpId: string;
    userName: string;
    firstName: string;
    lastName: string;
    userPermissions: IRolePermissions[];
    roleId: number;
  }
}

interface AuthState {
  user: User | null;
  isLoading: boolean;
  accessToken: string | null;
}

export interface AuthContextType extends AuthState {
  signIn: (username: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  logout: () => Promise<void>
  updateUser: (userData: User) => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [state, setState] = useState<AuthState>({
    user: null,
    isLoading: true,
    accessToken: null
  })
  const { toggleLayout } = useLayout();
  const { setTheme } = useTheme();
  const { resetExamData } = useExamStore();

  const router = useRouter()

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem("access_token")
        const userData = localStorage.getItem("user_data")
        
        if (!token || !userData) {
          router.push("/sign-in");
          setState({ user: null, isLoading: false, accessToken: null })
          return
        }
        const user = JSON.parse(userData)
        setState({ user, isLoading: false, accessToken: token })
      } catch (error) {
        setState({ user: null, isLoading: false, accessToken: null })
      }
    }
    checkAuth()
  }, [])

  const signIn = async (username: string, password: string): Promise<void> => {
    try {
      const response = await apiService.post<SignInResponse>(API_LIST.SIGNIN, { username, password });
      if(response?.success){
        const { user, accessToken, authToken } = response.data;
        const { userPermissions, userName, firstName, lastName, email, roleId } = user || {};
  
        // Store only required user data
        const userData: User = {
          userName,
          firstName,
          lastName,
          email,
          roleId: Number(roleId)
        };
         localStorage.setItem("access_token", accessToken);
         localStorage.setItem("auth_token", authToken);
         localStorage.setItem("user_data", JSON.stringify(userData));
         localStorage.setItem("user_permissions", JSON.stringify(userPermissions));
  
        setState({ user: userData, isLoading: false, accessToken: accessToken });
  
        try {
          const permissionResult = await Notification.requestPermission();
  
          if (permissionResult === "granted" && messaging) {
            const fcmToken = await getToken(messaging, { 
              vapidKey: process.env.NEXT_PUBLIC_VAPID_KEY 
            });
            // Send FCM token to backend
            await apiService.post(API_LIST.UPDATE_NOTIFICATION_TOKEN, {
              token: fcmToken,
              deviceType: DeviceTypes.WEB
            });
  
            const response = await apiService.get<any>(API_LIST.GET_PREFERENCE);
            const {darkTheme, layout } = response.data as Preference;
    
            setTheme(darkTheme ? 'dark' : 'light')
            toggleLayout(layout);
          }
        } catch (error) {
          console.error("Failed to setup notifications:", error);
          throw error;
        }
   
        router.push("/dashboard")
      }
    } catch (error: any) {
      throw error?.response?.data || { message: "Authentication failed" };
    }
  } 

  const signOut = async () => {
    try {
      const authToken = localStorage.getItem("auth_token");
      if (authToken) {
        await apiService.post(API_LIST.SIGNOUT, undefined, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'X-Skip-Auth': 'true'
          }
        });
      }
    } catch (error) {
      console.error('Sign out error:', error);
    } finally {
      localStorage.removeItem("access_token");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("user_data");
      resetExamData();
      setState({ user: null, isLoading: false, accessToken: null });
      router.push("/sign-in");
    }
  }

  const logout = async () => {
    localStorage.clear();
    setTheme('light');
    setState({ user: null, isLoading: false, accessToken: null });
    resetExamData();

    router.push("/sign-in")
  }

  const updateUser = (userData: User) => {
    setState(prev => ({ ...prev, user: userData }));
  }

  const contextValue: AuthContextType = useMemo(
    () => ({
      ...state,
      signIn,
      signOut,
      logout,
      updateUser,
    }),
    [state]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}