import { initializeApp } from "firebase/app";
import { getMessaging, type Messaging } from "firebase/messaging";
import { FirebaseConfig } from "@/types/notifications";

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// Create a messaging instance getter function
const getMessagingInstance = (): Messaging | null => {
  if (typeof window === "undefined") {
    console.warn("Firebase messaging is not available in this environment.");
    return null;
  }

  try {
    return getMessaging(app);
  } catch (error) {
    console.error("Error initializing Firebase messaging:", error);
    return null;
  }
};

// Export the getter function instead of the messaging instance
export const messaging = getMessagingInstance();
