'use client';

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

interface LayoutContextType {
  layout: boolean;
  toggleLayout: (value: boolean) => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export function LayoutProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [layout, setLayout] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem('layout-preference');
      return saved ? JSON.parse(saved) : false;
    }
    return false;
  });

  useEffect(() => {
    localStorage.setItem('layout-preference', JSON.stringify(layout));
  }, [layout]);

  const toggleLayout = (value: boolean) => {
    setLayout(value);
  };

  const value = useMemo(() => ({
    layout,
    toggleLayout
  }), [layout]);

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
}