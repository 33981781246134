"use client"

import React, { createContext, useContext, useMemo } from "react"
import type { SplitScreenStore } from "@/types/split-screen"
import { useSplitScreenStore } from "@/store/use-split-screen-store"

const SplitScreenContext = createContext<SplitScreenStore | undefined>(undefined)

interface SplitScreenProviderProps {
  readonly children: React.ReactNode
}

export function SplitScreenProvider({ children }: Readonly<SplitScreenProviderProps>) {
  const splitScreenStore = useSplitScreenStore()

  const contextValue: SplitScreenStore = useMemo(() => splitScreenStore, [splitScreenStore])

  return (
    <SplitScreenContext.Provider value={contextValue}>
      {children}
    </SplitScreenContext.Provider>
  )
}

export function useSplitScreen(): SplitScreenStore {
  const context = useContext(SplitScreenContext)
  if (context === undefined) {
    throw new Error("useSplitScreen must be used within a SplitScreenProvider")
  }
  return context
}

