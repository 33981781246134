'use client'

import { createContext, useContext, useRef, ReactNode, useMemo, useCallback } from 'react';
import { HEADER_OFFSETS } from '@/constants/layout';

interface ScrollContextType {
  anatomyScrollRef: React.RefObject<HTMLDivElement>;
  viewerScrollRef: React.RefObject<HTMLDivElement>;
  contentScrollRef: React.RefObject<HTMLDivElement>;
  scrollToAnatomy: (anatomyId: number) => void;
  scrollToSection: (sectionId: number, headerOffset: number) => void;
  scrollToContent: (contentId: number) => void;
}

const ScrollContext = createContext<ScrollContextType | null>(null);

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
  const anatomyScrollRef = useRef<HTMLDivElement | null>(null);
  const viewerScrollRef = useRef<HTMLDivElement | null>(null);
  const contentScrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToAnatomy = useCallback((anatomyId: number) => {
    const anatomyButton = anatomyScrollRef.current?.querySelector(
      `[data-anatomy-id="${anatomyId}"]`
    ) as HTMLElement | null;
    if (!anatomyButton || !anatomyScrollRef.current) return;

    const scrollPosition = anatomyButton.offsetLeft - anatomyScrollRef.current.clientWidth / 2;
    anatomyScrollRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
  }, []);

  const scrollToSection = useCallback((sectionId: number, headerOffset: number) => {
    const sectionElement = viewerScrollRef.current?.querySelector(
      `[data-section-id="${sectionId}"]`
    ) as HTMLElement | null;
    if (!sectionElement || !viewerScrollRef.current) return;
    const container = viewerScrollRef.current;
    const elementPosition = sectionElement.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    container.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }, []);

  const scrollToContent = useCallback((contentId: number) => {
    const contentElement = contentScrollRef.current?.querySelector(
      `[data-form-section-id="${contentId}"]`
    ) as HTMLElement | null;

    if (!contentElement || !contentScrollRef.current) return;
    
    const container = contentScrollRef.current;
    const headerOffset = HEADER_OFFSETS.DEFAULT;
    const elementPosition = contentElement.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    container.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }, []);

  const contextValue: ScrollContextType = useMemo(() => ({
    anatomyScrollRef: anatomyScrollRef as React.RefObject<HTMLDivElement>,
    viewerScrollRef: viewerScrollRef as React.RefObject<HTMLDivElement>,
    contentScrollRef: contentScrollRef as React.RefObject<HTMLDivElement>,
    scrollToAnatomy,
    scrollToSection,
    scrollToContent
  }), [scrollToAnatomy, scrollToSection, scrollToContent]);

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within ScrollProvider');
  }
  return context;
}; 