export interface Notification {
  id: number;
  content: string;
  time: string;
  label?: string;
  url?: string;
  isRead?: boolean;
  commentId?: number;
  frameId?: number;
  diaSeqId?: number;
  frameType?: number;
  diagnosticViewId?: number;
  anatomyId?: number;
  isResolved?: boolean;
  isSender?: boolean;
  commentContent?: string | null;
  coordinates?: string | null;
}

export interface NotificationsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  activeIcon: string | null;
  setActiveIcon: (icon: string | null) => void;
}

export interface FirebaseConfig {
  apiKey: string | undefined;
  authDomain: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId: string | undefined;
}

export interface MessageData {
  isMessage: boolean;
  conversationId?: number;
  userId?: number;
  message?: string;
}

export interface StoreNotification {
  deviceType : string;
  token: string;
}

export enum DeviceTypes {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}
