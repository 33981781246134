import { ReportStore } from "@/types/report";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useSaveReportStore = create<ReportStore>()(
  persist(
    (set, get) => ({
      saveReportRequestBody: {
        data: [],
        examId: 1, // Default examId
        templateId: 1, // Default templateId
      },
      isSaved: false,
      initialSaveState: true,

      updateReportData: (groupId, updates) =>
        set((state) => {
          const index = state.saveReportRequestBody.data.findIndex(
            (item) => item.groupId === groupId
          );

          let updatedData;
          if (index !== -1) {
            // Update existing group while keeping other properties intact
            updatedData = [...state.saveReportRequestBody.data];
            updatedData[index] = {
              ...updatedData[index],
              ...updates,
            };
          } else {
            // Add a new entry with default values
            updatedData = [
              ...state.saveReportRequestBody.data,
              {
                groupId,
                value: updates.value || [],
                text: updates.text ?? "",
              },
            ];
          }

          return {
            saveReportRequestBody: {
              ...state.saveReportRequestBody,
              data: updatedData,
            },
            isSaved: false,
            initialSaveState: false,
          };
        }),
      saveReportState: () => {
        set({ isSaved: true });
      },
    }),
    {
      name: "save-report-store",
      partialize: (state) => ({ isSaved: state.isSaved }),
    }
  )
);