import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { SplitScreenState, SplitScreenStore } from "@/types/split-screen";

const initialState: SplitScreenState = {
  isSplitScreen: window?.name === 'split-screen-window',
  toolsSection: false,
  windowRef: null,
  isSecondaryWindow: window?.name === 'split-screen-window'
};

// Create broadcast channel for window communication
const splitScreenChannel = typeof window !== 'undefined' ? new BroadcastChannel('split-screen-channel') : null;

interface StorageValue {
  state: SplitScreenState;
  version?: number;
}

export const useSplitScreenStore = create<SplitScreenStore>()(
  persist(
    (set, get) => {
      if (splitScreenChannel) {
        splitScreenChannel.onmessage = (event) => {
          if (event.data === 'close') {
            set({
              ...initialState,
              isSplitScreen: false,
              isSecondaryWindow: false
            });
            if (window.name === 'split-screen-window') {
              window.close();
            }
          } else if (event.data === 'open') {
            set(state => ({
              ...state,
              isSplitScreen: true
            }));
          }
        };
      }

      return {
        ...initialState,
        toggleSplitScreen: (examId: number) => {
          set((state) => {
            // If in split window, broadcast close message and close itself
            if (window.name === 'split-screen-window') {
              splitScreenChannel?.postMessage('close');
              window.close();
              return {
                ...initialState,
                isSplitScreen: false,
                isSecondaryWindow: false
              };
            }

            const newState: boolean = !state.isSplitScreen;

            // If turning off, broadcast close message
            if (!newState && state.windowRef) {
              splitScreenChannel?.postMessage('close');
              state.windowRef.close();
              return {
                ...initialState,
                isSplitScreen: false,
                isSecondaryWindow: false
              };
            }

            // If turning on split screen
            if (newState) {
              const windowRef: Window | null = window.open(
                `/report-section/${examId}`, 
                'split-screen-window',
              );
              if (!windowRef) {
                throw new Error("Popup blocked. Please allow popups for this site.");
              }

              // Broadcast open message to sync state
              splitScreenChannel?.postMessage('open');

              // Add event listener to detect when the window is closed
              const checkWindow = setInterval(() => {
                if (windowRef.closed) {
                  clearInterval(checkWindow);
                  splitScreenChannel?.postMessage('close');
                  set({
                    ...initialState,
                    isSplitScreen: false,
                    isSecondaryWindow: false
                  });
                }
              }, 500);

              return { 
                ...state,
                isSplitScreen: true,
                toolsSection: false,
                windowRef
              };
            }

            return state;
          });
        },
        closeSplitScreen: () => {
          set((state) => {
            // If in split window, broadcast close message and close itself
            if (window.name === 'split-screen-window') {
              splitScreenChannel?.postMessage('close');
              window.close();
              return {
                ...initialState,
                isSplitScreen: false,
                isSecondaryWindow: false
              };
            }

            // If turning off, broadcast close message
            if (state.windowRef) {
              splitScreenChannel?.postMessage('close'); // Notify split-screen
              state.windowRef.close(); // Close the window
              return {
                ...initialState,
                isSplitScreen: false,
                isSecondaryWindow: false
              };
            }

            return state;
          });
        },
        toggleToolsSection: () => {
          set((state) => ({
            ...state,
            toolsSection: !state.toolsSection,
          }));
        },
        setActiveSection: (sectionId: string) => {
          set((state) => ({
            ...state,
            activeSection: sectionId,
          }));
        },
      };
    },
    {
      name: 'split-screen-storage',
      storage: {
        getItem: (key: string) => {
          try {
            if (typeof window === 'undefined') return null;
            const item = localStorage.getItem(key);
            const parsedItem = item ? JSON.parse(item) : null;
            
            if (parsedItem && window.name === 'split-screen-window') {
              parsedItem.state = {
                ...parsedItem.state,
                isSplitScreen: true,
                isSecondaryWindow: true
              };
            }
            return parsedItem;
          } catch (err) {
            console.error('Error reading from storage:', err);
            return null;
          }
        },
        setItem: (key: string, value: StorageValue) => {
          try {
            if (typeof window === 'undefined') return;
            const valueToStore = {
              ...value,
              state: {
                ...value.state,
                windowRef: null,
                isSecondaryWindow: window.name === 'split-screen-window',
                isSplitScreen: value.state.isSplitScreen || window.name === 'split-screen-window'
              }
            };
            localStorage.setItem(key, JSON.stringify(valueToStore));
          } catch (err) {
            console.error('Error writing to storage:', err);
          }
        },
        removeItem: (key: string) => {
          try {
            if (typeof window === 'undefined') return;
            localStorage.removeItem(key);
          } catch (err) {
            console.error('Error removing from storage:', err);
          }
        }
      }
    }
  )
);